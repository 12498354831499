<template>
    <modal class="addPreMoneyModel" :isShow="isShow"  @keyup.esc="hide">
        <div class="header">新增押金</div>
        <div class="modal-body">
            <div class="tr">
                <div class="td-box">
                    <div class="lable-text">押金编号：</div>
                    <key-board-input class="from-input"
                        type="text" v-model="DepositCode" 
                        isBlurClose isKeyEnterClose
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
            </div>
            <div class="tr">
                <div class="td-box">
                    <div class="lable-text">客户姓名：</div>
                    <div class="from-input">
                        <input type="text" v-model="DepositName"/>
                    </div>
                </div>
                <div class="td-box">
                    <div class="lable-text">手机号码：</div>
                    <div class="from-input">
                        <input-pattern type="text" pattern="phone" v-model="DepositPhone"/>
                    </div>
                </div>
            </div>
            <div class="tr">
                <div class="td-box">
                    <div class="lable-text">付款方式：</div>
                    <div class="from-input">
                        <el-select v-model="Payment_ID" placeholder="请选择" value-key="item">
                            <el-option  v-for="item in payTypeList" :key="item.PAY_ID"  :label="item.PAY_NAME" :value="item.PAY_ID"> </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="td-box">
                    <div class="lable-text">押金金额：</div>
                    <key-board-input class="from-input"
                        type="text" v-model="DepositMoney" 
                        pattern="money"
                        isBlurClose isKeyEnterClose
                        :keyOptions="{isKeyDownEnter:true,isNumber:true}"
                    ></key-board-input>
                </div>
            </div>
            <div class="tr">
                <div class="td-box alignTop">
                    <div class="lable-text">备注：</div>
                    <div class="from-input remarks">
                        <textarea v-model="remarks"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:"addPreMoneyModel",
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
    },
    data(){
        return {
            //编号
            DepositCode:"",
            //名称
            DepositName:"",
            //电话
            DepositPhone:"",
            //押金
            DepositMoney:"",
           
            remarks:"",
             /**付款方式数据 */
            payTypeList:[],
            /**选中的 付款方式 */
            Payment_ID:'',
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.DepositCode="";
                this.DepositName="";
                this.DepositPhone="";
                this.DepositMoney="";
                this.remarks="";
                this.Payment_ID="";

                this.loadData();
            }
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        loadData(){
            if(this.payTypeList.length==0){
                this.$cacheData.AllPayTypes().then((d)=>{
                    if(d){
                        let all=[]
                        d.forEach(item => {
                            item.PayTypes.forEach((type)=>{
                                all.push(type)
                            })
                        });
                        this.payTypeList=all;
                    }
                }).catch((e)=>{
                    this.$message.error('加载付款方式数据失败：'+e,);
                    console.log('加载付款方式数据失败：'+e);
                })
            }
        },
        hide(){
            this.$emit("closeModel")
        },
        confirm(){
             this.$emit("confirm",{
                 DepositCode:this.DepositCode,
                 DepositName:this.DepositName,
                 DepositPhone:this.DepositPhone,
                 Payment_ID:this.Payment_ID,
                 DepositMoney:this.DepositMoney,
                 REMARK:this.remarks
             })
        }
    }
}
</script>

<style lang="scss">
@import './addPreMoneyModel.scss'
</style>