<template>
    <modal class="savePreMoneyModel" :isShow="isShow"  @keyup.esc="hide">
        <div class="header">押金管理</div>
        <div class="modal-body">
            <div class="filter-box">
                <div class="date-box">
                    <span>按日期范围：</span>
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                    > </el-date-picker>
                </div>
                <key-board-input class="search" placeholder="押金编码、客户姓名、手机号检索"
                    v-model="varietySelect"
                    :isBlurClose="true" :isKeyEnterClose="true"
                    :keyOptions="{isKeyDownEnter:true}"
                /> 
                <div class="query-bnt" @click="loadData()">查询</div>
            </div>
            <div class="content-box">
                <div class="left-box">
                    <div class="data-box">
                        <div class="panel-heading">
                            <div class="td order">序号</div>
                            <div class="td name">客户姓名</div>
                            <div class="td phone">手机号</div>
                            <div class="td deposit">收取押金金额</div>
                            <div class="td money">余额</div>
                            <div class="td number">押金编号</div>
                        </div>
                        <div class="scroll-box">
                            <div class="item-box" :class="{selected:selectData==item}" v-for="(item,index) in pageList" :key="index" @click="currentChange(item)">
                                <div class="tr">
                                    <div class="td order">{{(pageIndex-1)*pageSize+index+1}}</div>
                                    <div class="td name">{{item.DepositName}}</div>
                                    <div class="td phone">{{item.DepositPhone}}</div>
                                    <div class="td deposit red">¥{{item.DepositMoney}}</div>
                                    <div class="td money red">¥{{Number((item.DepositMoney-item.ConsumeMoney).toFixed(4))}}</div>
                                    <div class="td number">{{item.DepositCode}}</div>
                                </div>
                                <div class="more-box">
                                    <p>交易时间：{{(new Date(item.CREAT_TIME)).Format("yyyy/MM/dd hh:mm:ss")}}</p>
                                    <p class="pay">支付方式：{{ item.Payment_Name }}</p>
                                    <span class="print-bnt" @click="printReceipt(item)">重印小票</span>
                                </div>
                                <span class="tag-red" v-if="item.OrderFlag==1">红冲</span>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-search-box">
                        <div class="add-bnt" @click="add()"><i class="iconfont icon-jia"></i>新增押金</div>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                            <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>

                <div class="right-box" v-if="selectData">
                    <div class="data-box">
                        <div class="panel-heading">
                            <div class="td type">类型</div>
                            <div class="td money">交易金额</div>
                            <div class="td user">操作人</div>
                            <div class="td door">交易门店</div>
                            <div class="td operator"></div>
                        </div>
                        <div class="scroll-box" v-mouse-scroll v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.item-box'}">
                            <div class="item-box" :class="{selected:index==1}" v-for="(item,index) in selectData.DepositsConsumeInfoList" :key="index">
                                <div class="tr">
                                    <div class="td type">{{DepositEnum[item.DepositType]}}</div>
                                    <div class="td money red">¥{{item.ConsumeMoney}}</div>
                                    <div class="td user">{{item.CREAT_ID}}</div>
                                    <div class="td door">{{item.DepartmentName}}</div>
                                    <div class="td operator">
                                        <i class="iconfont icon-shanchu" @click="delData(item)" v-if="selectData.OrderFlag==0 && total.totalMoney>0 && item.DepositType==1"></i>
                                    </div>
                                </div>
                                <div class="more-box">
                                    <div class="flex-box">
                                        <p>交易时间：{{(new Date(item.CREAT_TIME)).Format("yyyy/MM/dd hh:mm:ss")}}</p>
                                        <p>支付方式：{{item.Payment_Name}}</p>
                                    </div>
                                    
                                    <p>备注：{{item.REMARK}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer orderFooter">
                        <div class="float-left txt">
                            <span class="small">余额：¥ </span>
                            <span class="money" v-html="total.moneyHtml"></span>
                        </div>
                        <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiangquan-top"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
        </div>
        <!-- 新增押金 -->
        <modal-load :isShow="addPreMoneyIshow">
            <add-pre-money-model :isShow="addPreMoneyIshow" @closeModel="addPreMoneyIshow=false" @confirm="addPreMoney"></add-pre-money-model>
        </modal-load>
    </modal>
</template>

<script>
import AddPreMoneyModel from './addPreMoneyModel/addPreMoneyModel.vue';

// 押金管理
export default {
    name:'savePreMoneyModel',
    components:{
        AddPreMoneyModel
    },
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            varietySelect:'',
            pageIndex:1,
            pageSize:7,
            listData:[],
            /**新增押金弹层 */
            addPreMoneyIshow:false,
            //选中的数据
            selectData:null
        }
    },
    computed:{
        //操作类型
        DepositEnum(){
            return {
                1:"收取押金",
                2:"抵扣押金" ,
                3:"冲正抵扣押金",
                4:"冲销收取押金"
            }
        },
        //余额
        total(){
            if(this.selectData){
                let money= this.selectData.DepositMoney-this.selectData.ConsumeMoney;
                return {
                    moneyHtml:money.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<i>'+s+'</i>'}),//展示到页面右下角余额
                    totalMoney:money//余额判断是否显示红冲
                }
            }
            return "";
        },
        dataOrder(){
            let data=(this.listData||[]);
            return data.order("DepositCode","ASC")
        },
        pageList(){
            return this.dataOrder?.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        pageCount(){
            if(this.listData){
                return Math.ceil(this.listData.length/this.pageSize)||1;
            }
            return 1;
        }
    },
    mounted(){
        
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo();
            this.businessHours=[this.userInfo.Rpt_Date,this.userInfo.Rpt_Date];
            this.loadData();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.loadData();
            }
        }
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        },
        //加载数据
        loadData(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            const loading = this.$loading({
                text: "获取押金数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetDepositsInfo", {
                User_ID:userInfo.User_ID,
                BeginDate:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                EndDate:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                SearchText:(this.varietySelect||"").trim()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.listData=d.ResponseBody||[];
                    this.pageIndex=1;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("押金管理数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        currentChange(item){
            this.selectData=item;
        },
        add(){
            this.addPreMoneyIshow=true;
        },
        delData(item){
            this.$confirm("您确定冲红押金吗？","提示", {
                confirmButtonText: "继续",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.RedDeposit(item);
                    }
                },
            })
        },
        RedDeposit(item){//冲红收取押金
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.RedDeposit",{
                Autoid:item.Autoid,
                Timestamps:this.selectData.Timestamps,
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("操作成功");
                    console.log("操作成功",d.ResponseBody)
                    if(d.ResponseBody){
                        let index= this.listData.findIndex(item=>item==this.selectData);
                        if(index>=0){
                            this.listData[index]=d.ResponseBody;
                            this.selectData=d.ResponseBody;
                        }
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('冲红收取押金失败：'+e.message);
                console.log('冲红收取押金失败：',e);
            })
        },
        /**点击确认 新增押金数据 */
        addPreMoney(info){
            if(!info){return;}
            if(!info.DepositCode){
                this.$message.warning("押金编号不能为空!");
                return;
            }
            if(!info.DepositName){
                this.$message.warning("客户姓名不能为空!");
                return;
            }
            
            if(!info.DepositPhone){
                this.$message.warning("手机号码不能为空!");
                return;
            }
            if(info.DepositPhone.length!=11){
                this.$message.warning("手机号码格式不正确!");
                return;
            }
            if(!info.Payment_ID){
                this.$message.warning("付款方式不能为空!");
                return;
            }
            if(!info.DepositMoney>0){
                this.$message.warning("押金金额不能小于等于0!");
                return;
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.AddDeposit",{
                depositsInfo:info,
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    console.log("保存成功",d.ResponseBody)
                    if(d.ResponseBody){
                        if(!this.listData){this.listData=[];}
                        this.listData.push(d.ResponseBody);
                        this.addPreMoneyIshow=false;
                        if(d.ResponseBody.PrintsInfo){//有打印数据
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        }
                       
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('新增押金保存失败：'+e.message);
                console.log('新增押金保存失败：',e);
            })
        },
        /**重印小票 */
        printReceipt(item){
            const loading = this.$loading({
                text: "请求打印数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",{
                Operator_Name:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID,
                PrintType:903,
                Order_ID:item.Autoid
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                        this.$message.success("已发送打印指令");
                    }else{
                        this.$message.error('请求设备没有相关打印设置!');
                    }
                    
                }else{
                    this.$message.error("请求打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求打印数据失败：'+e);
                console.log('请求打印数据失败：'+e);
            })
        },
    }
}
</script>

<style lang="scss">
@import './savePreMoneyModel.scss'
</style>